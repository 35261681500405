import React, { useState, useEffect } from 'react';
import GatsbyLink from 'gatsby-link';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { graphql } from 'gatsby';
import styled from 'styled-components/macro';

import Layout from '../components/Layout';
import TopHeader from '../views/shared/TopHeaderV2';
import SocialProofSection from '../views/LandingPage/SocialProofSection';
import ContactSection from '../components/ContactSection';
import HRSolutions from '../views/LandingPage/HRSolutions';
import HeaderSeo from '../components/HeaderSeo';
import GogetPartners from '../views/BusinessPage/GogetPartners';
import BusinessLogoSlider from '../views/BusinessPage/BusinessLogoSlider';
import ProjectAccessPoint from '../views/LandingPage/ProjectAccessPoint';

import FAQSection from '../views/shared/FAQ';

import AppStoreDownload from '../assets/img/AppStoreDownload.svg';
import PlayStoreDownload from '../assets/img/PlayStoreDownload.svg';
import BusinessIcon from '../assets/img/BusinessIcon.svg';
import HomeLifeIcon from '../assets/img/HomeLifeIcon.svg';
import GogetterIcon from '../assets/img/GogetterIcon.svg';

import MetaImg from '../assets/img/meta/index.png';

import FAQFormBackgroundMobile from '../assets/img/main/FAQFormBackgroundMobile.png';
import BackgroundFAQFormDesktop from '../assets/img/main/BackgroundFAQFormDesktop.png';

import Divider from '@material-ui/core/Divider';

import Link from '@material-ui/icons/Link';

import colors from '../assets/colors';

import { SharedBackground } from '../assets/sharedStyle';

const StyledImg = styled.img`
  &&& {
    height: 28px;
    width: 28px;
  }
  margin-right: 10px;
`;
const TextWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${props => props.margin && '4px'};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
`;
const Text = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  margin: unset;
`;
const LinkWrapper = styled.div`
  display: flex;
  align-items: center;
  color: ${colors.primary};
`;
const DownloadButtonsWrap = styled.div`
  display: flex;
  margin-bottom: 1rem;
`;
const DownloadButtonImg = styled.img`
  width: 120px;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  margin-right: 0.5rem;
`;
const LinkIcon = styled(Link)`
  font-size: 1rem;
  margin-right: 4px;
  color: ${colors.black};
`;
const FAQContactBackground = styled.div`
  ${SharedBackground};
  background-image: none;
  @media (max-width: 760px) {
    background-image: url(${FAQFormBackgroundMobile});
  }
`;
const BackgroundWrapper = styled.div`
  background-image: url(${BackgroundFAQFormDesktop});
  ${SharedBackground};
  @media (max-width: 760px) {
    background-image: none;
    padding-top: 0;
  }
`;
const HRSolutionSections = styled.div`
  padding: 1rem 0;
  width: 100%;
  margin: auto;
  max-width: 1400px;
`;

const IndexPage = props => {
  const [mobile, setMobile] = useState(false);
  const {
    location,
    data: { datoCmsHome, datoCmsSlider }
  } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
    window.addEventListener('resize', sizeHandler);
    return sizeHandler();
  }, [mobile]);

  const sizeHandler = () => {
    if (window.innerWidth <= 800) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  };

  const FaqData = [
    {
      question: 'How much should I pay workers?',
      content: (
        <Text>
          The price is calculated on the GoGet platform based on the distance,
          time and effort of the job.
          <br />
          <br />
          For Businesses please learn more{' '}
          <GatsbyLink to="/business/pricing">here</GatsbyLink> <br />
          For Regular users please Learn more{' '}
          <GatsbyLink to="/homelife/pricing">here</GatsbyLink>
        </Text>
      )
    },
    {
      question: 'What is the cost required to use GoGet?',
      content: (
        <Text>
          There's absolutely no cost to you to hire on GoGet, except for the
          fees you should pay workers. All you need is an email and mobile
          number. One-time setup fees or additional costs will only incur if you
          have specific requirements for training or end-to-end service.
        </Text>
      )
    },
    {
      question: "What is GoGet's coverage?",
      content: (
        <Text>
          GoGetters are mainly available in the Klang Valley, Penang, Negeri
          Sembilan and Johor Bahru. If you have your own fleet of workers, but
          looking for a system to manage workers and jobs, please drop us your
          details we will reach out to you.
        </Text>
      )
    },
    {
      question: 'What payment options are available?',
      content: (
        <Text>
          There are a few options available depending on your GoGet account
          type.
          <br />
          <br />
          <b>For Business accounts </b>
          <br />
          - GoGet credits
          <br />
          Learn more <GatsbyLink to="/business/pricing">here</GatsbyLink>
          <br />
          <br />
          <b>For Regular accounts</b>
          <br />
          - GoGet credits
          <br />
          - GoGet Pay (Only available for food & shopping jobs)
          <br />
          Learn more <GatsbyLink to="/homelife/pricing">here</GatsbyLink>
        </Text>
      )
    },
    {
      question: 'Do I need anything to get started?',
      content: (
        <>
          <TextWrapper margin>
            <StyledImg src={BusinessIcon} alt="" />
            <b>
              For Business users - <br />
            </b>
          </TextWrapper>
          <Text>
            Purchase one of the business credit packages, and instantly become a
            GoGet Business account. <br />
            <br />
          </Text>
          <TextWrapper margin>
            <StyledImg src={HomeLifeIcon} alt="" />
            <b>
              For Home & Life users - <br />
            </b>
          </TextWrapper>
          <Text>All you need is a verified GoGet account.</Text>
          <br />
          <TextWrapper margin>
            <StyledImg src={GogetterIcon} alt="" />
            <b>
              For GoGetters - <br />
            </b>
          </TextWrapper>
          <Text>
            Get verified as a GoGetter through our online application and start
            earning money as a verified GoGetter.
          </Text>
        </>
      )
    },
    {
      question: 'What platform is GoGet available on?',
      content: (
        <>
          <Text>
            To find part timers using our web app:
            <br />
            <br />
          </Text>
          <LinkWrapper>
            <LinkIcon />
            <a
              href={process.env.GATSBY_PWA_PATH}
              style={{ textDecoration: 'none' }}
            >
              {process.env.GATSBY_PWA_PATH}
            </a>
          </LinkWrapper>
          <br />
          <br />
          <b>To find part timers:</b>
          <br />
          Download our "GoGet" app
          <br />
          <br />
          <b>To earn with GoGet:</b>
          <br />
          Download our "GoGetter- Part timer community" app
          <br />
          <DownloadButtonsWrap mobile={mobile}>
            <a
              href="https://play.google.com/store/apps/details?id=my.goget.gogetter&hl=en"
              style={{ marginRight: '1rem' }}
            >
              <DownloadButtonImg
                alt=""
                src={PlayStoreDownload}
                mobile={mobile}
              />
            </a>
            <a href="https://apps.apple.com/my/app/gogetter-part-timer-community/id1483160371">
              <DownloadButtonImg
                alt=""
                src={AppStoreDownload}
                mobile={mobile}
              />
            </a>
          </DownloadButtonsWrap>
        </>
      )
    },
    {
      question: 'Can I hire for any kind of jobs?',
      content: (
        <Text>
          You can hire any job scopes available on our app. GoGet workforce is
          highly targeted to only non-executive job scopes. We have experienced
          packers, retail staff, kitchen helpers, floor staff, event crews,
          callers and many more.
        </Text>
      )
    },
    {
      question: "What are your workers' demographics?",
      content: (
        <Text>
          Our workforce consists of all ages starting from 18 years old and
          above with a balanced mix of gender.
        </Text>
      )
    },
    {
      question: "What happens if your worker doesn't turn up for hourly jobs?",
      content: (
        <Text>
          Our internal system checks for attendance and timeliness, you will be
          given time to review and approve or reject payment for such event.
          Your report to us helps to improve the attendance rate, and the job
          fee will be credited back to your GoGet Credits.
        </Text>
      )
    },
    {
      question:
        'What happens if your worker last minute cancel for an hourly job?',
      content: (
        <Text>
          You will see it on our dashboard if a worker cancel last minute, and
          our system will automatically repost a job to get a replacement for
          you.
        </Text>
      )
    },
    {
      question: 'Can I hire your GoGetters full time?',
      content: (
        <Text>
          Yes! You may list a job on GoGet Recruit to hire a trusted GoGetter
          for longer term based on your own terms i.e contract, part time or
          full time.
        </Text>
      )
    }
  ];

  const desktopImage = getImage(datoCmsHome?.bannerImageDesktop);
  const mobileImage = getImage(datoCmsHome?.bannerImageMobile);

  return (
    <>
      <HeaderSeo
        title="GoGet | On Demand Work App For Businesses and Part Time Workers"
        description="GoGet is an on demand workforce technology powered by a network of verified people, called GoGetters, to do work. We connect businesses to workers instantly."
        image={MetaImg}
        keywords="goget, find part timer, freelance job malaysia"
        pathname={location.pathname}
        schemaMarkup={datoCmsHome.schemaMarkup}
      />
      <Layout showHeaderButtons isShowingFooterAppBar>
        <TopHeader
          backgroundImg={
            <GatsbyImage
              image={desktopImage}
              loading="eager"
              style={{ height: '100%' }}
              alt={datoCmsHome?.bannerImageDesktop?.alt}
            />
          }
          mobileBackgroundImg={
            <GatsbyImage
              image={mobileImage}
              loading="eager"
              alt={datoCmsHome?.bannerImageMobile?.alt}
            />
          }
          showVideo={true}
          title={datoCmsHome?.headerTitle}
          description={datoCmsHome?.description}
          btn1={{ text: 'CREATE JOB', link: process.env.GATSBY_PWA_PATH }}
          backgroundMobileHeight="600px"
          hidePlay
          main
        />
        <SocialProofSection
          accolades={datoCmsHome?.accolades}
          verifiedGogetters={datoCmsHome?.verifiedGogetters}
          activeUsers={datoCmsHome?.activeUsers}
          ratings={datoCmsHome?.ratings}
          mobile={mobile}
        />
        <HRSolutionSections>
          <HRSolutions mobile={mobile} sections={datoCmsHome?.heroSections} />
        </HRSolutionSections>
        <Divider style={{ maxWidth: mobile ? '' : '1300px', margin: 'auto' }} />
        <ProjectAccessPoint />
        <GogetPartners title="Partners we've worked with" />
        <BusinessLogoSlider logos={datoCmsSlider?.businessLogos} />
        <BackgroundWrapper>
          <FAQContactBackground>
            <FAQSection data={FaqData} styleProps={'padding-top: 0;'} />
          </FAQContactBackground>
          <div id="contact-us">
            <ContactSection
              title={<div>Let us help grow your business! </div>}
              subtext="Provide us with your details and we will get in touch."
              textBlack={mobile}
              hideBackground
            />
          </div>
        </BackgroundWrapper>
      </Layout>
    </>
  );
};

export default IndexPage;

export const query = graphql`
  query HomeQuery {
    datoCmsHome {
      headerTitle
      activeUsers
      description
      ratings
      verifiedGogetters
      bannerImageDesktop {
        gatsbyImageData(placeholder: BLURRED, forceBlurhash: false)
        alt
      }
      bannerImageMobile {
        gatsbyImageData(width: 600, placeholder: BLURRED, forceBlurhash: false)
        alt
      }
      accolades {
        originalId
        image {
          gatsbyImageData(
            width: 300
            placeholder: BLURRED
            forceBlurhash: false
          )
          alt
        }
        text
        url
      }
      heroSections {
        originalId
        description
        ctaPath
        ctaText
        subtitle
        title
        heroImage {
          gatsbyImageData(
            forceBlurhash: false
            width: 800
            placeholder: BLURRED
          )
          alt
        }
      }
      schemaMarkup
    }
    datoCmsSlider {
      businessLogos {
        gatsbyImageData(width: 300, forceBlurhash: false, placeholder: BLURRED)
        alt
      }
    }
  }
`;
