import React from 'react';
import styled from 'styled-components/macro';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import ArrowIcon from '@material-ui/icons/ArrowForward';
import Paper from '@material-ui/core/Paper';

import colors from '../../assets/colors';
import fontSize from '../../assets/fontSize';

const Container = styled.div`
  width: 100%;
  min-height: 200px;
`;
const MainWrap = styled.div`
  padding-left: ${props => (props.mobile ? '0.5rem' : '1rem')};
  padding-right: ${props => (props.mobile ? '0.5rem' : '1rem')};
  padding-bottom: 1rem;
  display: flex;
  flex-direction: column;
  position: relative;
  top: -20px;
`;
const RowWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: ${props => props.marginTop && '1rem'};
  @media (max-width: 760px) {
    flex-direction: column;
    margin-top: ${props => props.marginTop && '3rem'};
  }
`;
const AccoladesSection = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 2rem;
  padding: 2rem;
  background-color: ${colors.lightGrey};
  border-radius: 20px;
  margin: 0 auto;
  margin-top: 3rem;
  max-width: 1300px;

  @media (min-width: 740px) {
    grid-gap: 3rem;
    padding: 3rem;
  }
`;
const AccoladeWrap = styled.a`
  grid-column: span 3;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  color: ${colors.black};
  text-decoration: none;

  @media (min-width: 740px) {
    flex-direction: row;
  }

  @media (min-width: 960px) {
    grid-column: span 2;
  }
`;
const StyledArrowIcon = styled(ArrowIcon)`
  display: none;

  @media (min-width: 740px) {
    display: block;
  }
`;
const AccoladeText = styled.div`
  flex: 1;
  font-size: ${fontSize.large};
  text-align: center;
  white-space: pre-wrap;

  @media (min-width: 740px) {
    text-align: left;
  }
`;
const Tile = styled(Paper)`
  width: 239px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-right: 64px;
  &:last-child {
    margin-right: 0;
  }
  min-height: ${props => props.mobile && '80px'};
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05) !important;
  border-radius: 20px !important;
  @media (max-width: 760px) {
    margin-top: 27px;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    &:first-child {
      margin-top: 0;
    }
  }
`;
const TileTitle = styled.div`
  font-family: 'Open Sans', sans-serif !important;
  font-style: normal;
  font-weight: bold;
  font-size: 52px;
  line-height: 71px;
  font-weight: 700;
  @media (max-width: 760px) {
    font-size: 42px;
    line-height: 57px;
  }
  color: ${props =>
    props.isPurple
      ? `${colors.secondary}`
      : props.isTeal
      ? `${colors.primary}`
      : `${colors.black}`};
`;
const TileDescription = styled.div`
  flex: 1;
  font-size: ${fontSize.large};
  text-align: ${props => props.mobile && 'center'};
  font-family: 'Open Sans', sans-serif !important;
  font-style: normal;
  font-weight: 600;
  line-height: 19px;
  ${props =>
    props.brand &&
    `
      @media (max-width: 760px) {
        font-family: 'Open Sans', sans-serif !important;
        line-height: 18px;
        font-weight: normal;
        margin-right: 8px;
      }
    `}
`;

const SocialProofSection = props => {
  const { mobile, verifiedGogetters, accolades, ratings, activeUsers } = props;

  return (
    <Container mobile={mobile ? 'true' : undefined}>
      <MainWrap mobile={mobile ? 'true' : undefined}>
        <RowWrap>
          <Tile mobile={mobile ? 'true' : undefined}>
            <TileTitle isPurple mobile={mobile ? 'true' : undefined}>
              {activeUsers}K+
            </TileTitle>
            <TileDescription mobile={mobile ? 'true' : undefined}>
              Users
            </TileDescription>
          </Tile>
          <Tile mobile={mobile ? 'true' : undefined}>
            <TileTitle isTeal mobile={mobile ? 'true' : undefined}>
              {ratings}%
            </TileTitle>
            <TileDescription mobile={mobile ? 'true' : undefined}>
              Good Ratings
            </TileDescription>
          </Tile>
          <Tile mobile={mobile ? 'true' : undefined}>
            <TileTitle mobile={mobile ? 'true' : undefined}>
              {verifiedGogetters}K+
            </TileTitle>
            <TileDescription mobile={mobile ? 'true' : undefined}>
              Verified Part Timers
            </TileDescription>
          </Tile>
        </RowWrap>
        <AccoladesSection>
          {accolades.map(accolade => {
            const image = getImage(accolade.image);

            return (
              <AccoladeWrap
                key={accolade.originalId}
                href={accolade.url}
                target="_blank"
              >
                <GatsbyImage
                  image={image}
                  alt={accolade.image.alt}
                  style={{ width: '100px' }}
                />
                <AccoladeText>{accolade.text}</AccoladeText>
                <StyledArrowIcon />
              </AccoladeWrap>
            );
          })}
        </AccoladesSection>
      </MainWrap>
    </Container>
  );
};

export default SocialProofSection;
